'use client';
import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import { getImageProps } from 'next/image';

import { getImageLink } from '@/services/link.service';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './WarrantySection.module.scss';

type Props = {
  warranty_title: string | undefined;
  warranty_description: string;
  warranty_image: string | undefined;
};

const WarrantySection = ({
  warranty_title,
  warranty_description,
  warranty_image,
}: Props) => {
  const imageKeys = {
    desktopKey: 'image-192-244',
    mobileKey: 'image-124-158',
  };
  const common = {
    alt: 'Hero',
    width: 192,
    height: 244,
    loader: directusImageLoader,
  };
  const image = getImageLink(warranty_image);
  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    src: image ? `${image}?key=${imageKeys.mobileKey}` : '',
  });

  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({
    ...common,
    src: image ? `${image}?key=${imageKeys.desktopKey}` : '',
  });

  return (
    <Container
      maxWidth={'100%'}
      paddingX={{ base: 8, lg: 0 }}
      paddingY={{ base: 10, md: 12 }}
      className={Styles.warrantySectionWrapper}
    >
      <Flex
        direction={['column', 'row']}
        flexWrap={'wrap'}
        alignItems={'start'}
        justifyContent={'center'}
        maxWidth={{ base: '100%', md: '880px' }}
        marginX={'auto'}
        gap={{ base: '6', md: '20' }}
      >
        <picture className={Styles.warrantyImg}>
          <source media="(max-width: 575px)" srcSet={mobile} />
          <source media="(min-width: 576px)" srcSet={desktop} />
          <img {...rest} alt="hero-banner-image" />
        </picture>

        <Box
          className={Styles.warrantyDetail}
          textAlign={{ base: 'center', md: 'left' }}
          width={'100%'}
          maxWidth={'540px'}
          marginX={'auto'}
        >
          <Box className={Styles.headingElement}>
            <Heading as="h2" className={Styles.titleLg} marginBottom={4}>
              {warranty_title}
            </Heading>
          </Box>

          <div
            dangerouslySetInnerHTML={{ __html: warranty_description }}
            className={Styles.titleText}
          ></div>
        </Box>
      </Flex>
    </Container>
  );
};

export default WarrantySection;
