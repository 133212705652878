'use client';
import { Box, Container, Heading } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as ArrowBackwardCircleDark } from '@/app/_icons/ArrowBackwardCircleDark.svg';
import { ReactComponent as ArrowForwardCircleDark } from '@/app/_icons/ArrowForwardCircleDark.svg';
import MeetTeamCard from '@/app/dealerships/[id]/_components/meet-team-card/MeetTeamCard';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import styles from './MeetTheTeam.module.scss';
type Teams = {
  email: string;
  full_name: string;
  image_id: string;
  role: string;
  phone_number: string;
};
const MeetTheTeam = ({
  teams,
  defaultImage,
}: {
  teams: Teams[];
  defaultImage: string;
}) => {
  const swiperRef: any = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const CustomNavigation = () => {
    return (
      <div className={styles.swiperNavigation}>
        <button
          aria-label="prev slide"
          onClick={goPrev}
          className={styles.swiperButtonPrev1}
        >
          <ArrowBackwardCircleDark />
        </button>
        <button
          aria-label="next slide"
          onClick={goNext}
          className={styles.swiperButtonNext1}
        >
          <ArrowForwardCircleDark />
        </button>
      </div>
    );
  };

  return (
    <>
      <Box
        className={styles.meetTheTeamWrapper}
        paddingY={{ base: 14, lg: '100px' }}
      >
        <Container
          maxW={1280}
          width={'100%'}
          className={styles.meetTheTeamHeader}
          marginBottom={{ base: 16, md: 10 }}
          paddingX={{ base: 6, md: '56px' }}
        >
          <Box
            className={styles.headingElement}
            mb={'8!important'}
            px={'0!important'}
          >
            <Heading as="h2" className={styles.titleLg} textAlign={'center'}>
              Meet the Team
            </Heading>
          </Box>
        </Container>
        <Box
          maxWidth={1280}
          width={'100%'}
          marginX={'auto'}
          paddingX={{ base: 0, sm: '6', md: '56px' }}
        >
          <Swiper
            className={styles.meetTheTeamSlider}
            ref={swiperRef}
            slidesPerView={4}
            spaceBetween={40}
            modules={[Pagination, Navigation]}
            pagination={{
              el: `.${styles.swiperPagination}`,
              type: 'fraction',
              formatFractionCurrent: function (number) {
                return number < 10 ? '0' + number : number.toString();
              },
            }}
            navigation={{
              nextEl: `.${styles.swiperButtonNext1}`,
              prevEl: `.${styles.swiperButtonPrev1}`,
            }}
            onSlideChange={(swiper) => {
              setCurrentPage(swiper.realIndex + 1);
            }}
            onSwiper={(swiper) => {
              setTotalPages(swiper.slides.length);
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              576: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              640: {
                slidesPerView: 1,
              },
              641: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
          >
            {teams?.map((team, i) => {
              return (
                <SwiperSlide key={i} style={{ height: 'auto' }}>
                  <MeetTeamCard
                    image={''}
                    {...team}
                    imageKeys={{
                      desktopKey: 'image-416-416',
                      mobileKey: 'image-360-360',
                    }}
                    defaultImage={defaultImage}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
        <Box
          className={styles.meetTheTeamSwiperControls}
          paddingX={{ base: 6, md: '56px' }}
        >
          <Box className={styles.swiperPagination}>
            <span className="current-page">{currentPage}</span>
            <span className="separator">/</span>
            <span className="total-pages">{totalPages}</span>
          </Box>
          <CustomNavigation />
        </Box>
      </Box>
    </>
  );
};

export default MeetTheTeam;
