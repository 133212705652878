import { Box, Container } from '@chakra-ui/react';
import cn from 'classnames';
import Image from 'next/image';

import { DealershipProps } from '@/types/dealership/dealership.types';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './BannerSection.module.scss';
import ServiceSection from './ServiceSection';

type Props = {
  mainImage: string;
  name: string;
  dealerInfo: DealershipProps;
};

const BannerSection = ({ mainImage, name, dealerInfo }: Props) => {
  return (
    <>
      <Box
        className={cn(
          Styles.bannerSectionWrapper,
          dealerInfo?.header_call_to_actions.length === 0 &&
            Styles.bannerSectionNoCTA,
        )}
        display={'flex'}
        flexDirection={{ base: 'column' }}
        alignItems={'center'}
        height={{ base: 'auto', lg: '330px' }}
      >
        <Box
          className={Styles.bannerContentSection}
          display={{ lg: 'flex' }}
          alignItems={{ lg: 'flex-end' }}
        >
          <Image
            src={mainImage}
            loader={directusImageLoader}
            objectFit="cover"
            layout="fill"
            className={Styles.bannerSectionImage}
            alt="Hero Banner"
            priority
          />
        </Box>
        {dealerInfo?.header_call_to_actions &&
          dealerInfo?.header_call_to_actions.length > 0 && (
            <Box className={Styles.dealershipBannerCTA}>
              <Container
                maxW={'1280'}
                width={'100%'}
                alignContent={'center'}
                px={0}
              >
                <ServiceSection
                  name={name}
                  header_call_to_actions={dealerInfo?.header_call_to_actions}
                />
              </Container>
            </Box>
          )}
      </Box>
    </>
  );
};

export default BannerSection;
